<template>
  <div>
    <div class="displayResult">
      <b-container>
        <b-row>
          <b-col md="12">
            <div class="evaluationSheet bg-white rounded p-3">
              <div
                class="heading mb-2 text-center text-info font-weight-bolder"
              >
                Evaluation Result for: {{ exam.name }}
              </div>
              <div
                class="question mb-2"
                v-for="({ id, question_no, name, choices },
                quesIndex) in examQuestions"
                :key="quesIndex"
              >
                <p class="quesAndNo">{{ question_no }} {{ name }}</p>
                <b-form-radio
                  :class="
                    choice.is_right_choice == 1
                      ? 'rightChoice choiceText'
                      : 'wrongChoice choiceText'
                  "
                  v-for="(choice, choiceIndex) in choices"
                  disabled
                  :key="choiceIndex"
                  v-model="examQuestionsAnswer[id].choice_id"
                  :value="choice.id"
                >
                  {{ choice.name }}
                </b-form-radio>
                <b-alert
                  :variant="
                    examQuestionsAnswer[id].score != null
                      ? 'success'
                      : 'warning'
                  "
                  class="font-weight-bolder mt-2"
                  show
                >
                  <span v-if="examQuestionsAnswer[id].score == null">
                    Score Pending
                  </span>
                  <span v-else>
                    Score:
                    {{ examQuestionsAnswer[id].score }}
                  </span>
                </b-alert>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  props: ["exam", "examQuestions", "examQuestionsAnswer", "examAnswers"],
  data() {
    return {};
  },
};
</script>

<style >
.choiceText label {
  font-weight: 600 !important;
}

.rightChoice label {
  color: green !important;
}

.wrongChoice label {
  color: red !important;
}
</style>
