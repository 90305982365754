var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"displayResult"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"evaluationSheet bg-white rounded p-3"},[_c('div',{staticClass:"heading mb-2 text-center text-info font-weight-bolder"},[_vm._v(" Evaluation Result for: "+_vm._s(_vm.exam.name)+" ")]),_vm._l((_vm.examQuestions),function(ref,quesIndex){
var id = ref.id;
var question_no = ref.question_no;
var name = ref.name;
var choices = ref.choices;
return _c('div',{key:quesIndex,staticClass:"question mb-2"},[_c('p',{staticClass:"quesAndNo"},[_vm._v(_vm._s(question_no)+" "+_vm._s(name))]),_vm._l((choices),function(choice,choiceIndex){return _c('b-form-radio',{key:choiceIndex,class:choice.is_right_choice == 1
                    ? 'rightChoice choiceText'
                    : 'wrongChoice choiceText',attrs:{"disabled":"","value":choice.id},model:{value:(_vm.examQuestionsAnswer[id].choice_id),callback:function ($$v) {_vm.$set(_vm.examQuestionsAnswer[id], "choice_id", $$v)},expression:"examQuestionsAnswer[id].choice_id"}},[_vm._v(" "+_vm._s(choice.name)+" ")])}),_c('b-alert',{staticClass:"font-weight-bolder mt-2",attrs:{"variant":_vm.examQuestionsAnswer[id].score != null
                    ? 'success'
                    : 'warning',"show":""}},[(_vm.examQuestionsAnswer[id].score == null)?_c('span',[_vm._v(" Score Pending ")]):_c('span',[_vm._v(" Score: "+_vm._s(_vm.examQuestionsAnswer[id].score)+" ")])])],2)})],2)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }