<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else>
      <b-container>
        <div class="closeBtn mb-2 text-right">
          <p @click="$router.push({ name: 'institute' })" class="btn m-0">
            <i class="fas fa-window-close fa-4x text-danger"></i>
          </p>
        </div>
      </b-container>
      <DisplayResult :exam="exam" :examQuestions="examQuestions" :examQuestionsAnswer="examQuestionsAnswer"
        v-if="displayResult" />
      <b-container fluid v-else>
        <br />
        <b-row>
          <b-col md="12">
            <b-modal id="finishExam" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
              hide-header hide-footer centered size="sm">
              <p class="text-danger" style="font-size: 18px">
                <span v-if="exam.activity_type == 'assessment'">Are you sure you want to end the exam ?</span>
                <span v-else>Are you sure you want to submit the assignment ?</span>
              </p>
              <p class="text-info" style="font-size: 18px"
                v-if="(exam.activity_type == 'assessment' && !exam.publish_result)">
                (you can return to this page before time up)
              </p>
              <p class="text text-danger" v-if="exam.publish_result">
                You cannot return to this page once Submitted
              </p>
              <div class="acceptOrNot">
                <b-button :disabled="buttons.saveChanges.disabled" variant="primary" size="md" @click="finishExam"
                  pill>Yes</b-button>
                <b-button :disabled="buttons.saveChanges.disabled" variant="danger" class="ml-3" size="md"
                  @click="$bvModal.hide('finishExam')" pill>No</b-button>
              </div>
            </b-modal>

            <h3 style="font-size: 24px; font-weight: 900">
              Answers for: <span class="text-info">{{ exam.name }}</span>
            </h3>
            <br>
            <p>
              <span v-if="exam.positive_mark"> Score for right answer:{{ exam.positive_mark }}</span>
              <span v-if="exam.negative_mark"> Negative Score for wrong answer answer:{{ exam.negative_mark }}</span>
            </p>

            <br />
            <div class="finishExam text-left">
              <vue-countdown v-if="examStart" :time="(remainingTimeInSeconds * 1000)"
                v-slot="{ hours, minutes, seconds }" @end="examTimeEnded">
                <h5>
                  <b :class="{ 'time-running-low': (hours == 0 && minutes < 5) }">
                    <span class="text-info"><i class="fas fa-hourglass-start"></i></span>
                    {{ hours }} hr {{ minutes }} min, {{ seconds }} sec
                  </b>
                </h5>
              </vue-countdown>
            </div>
            <div class="finishExam text-right">
              <b-button :disabled="buttons.saveChanges.disabled" variant="success" size="md"
                @click="saveQuestionsAnswers">{{ buttons.saveChanges.text }}</b-button>
              <b-button :disabled="buttons.saveChanges.disabled" variant="danger" size="md" class="ml-3"
                @click="$bvModal.show('finishExam')">{{
                    exam.activity_type == "assessment"
                      ? "Finish Exam"
                      : "Submit Assignment"
                }}</b-button>
            </div>

            <div class="answersToQuestions">
              <div v-for="(question, questionIndex) in examQuestions" :key="questionIndex" class="answers">
                <b-overlay :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
                  <b-card class="mt-3" header-tag="header" footer-tag="footer">
                    <template #header>
                      <div class="question d-flex justify-content-between align-items-baseline">
                        <p class="text-dark">
                          <span v-if="exam.shuffle_questions">{{ (questionIndex + 1) }}</span>
                          <span v-else>
                            {{ question.question_no != "" ? question.question_no + ": " : "" }}
                          </span>

                          <span v-for="(qData, qdIndex) in question.question" :key="qdIndex" class="mr-1">
                            <span v-if="qData.type == 'text'">{{ qData.value }}</span>
                            <math-field v-if="qData.type == 'equation'" class="mx-1" style="border: none;" readonly>{{
                                qData.value
                            }}</math-field>
                            <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style=""
                              :src="(cloudStorageRoot + questionAttachmentDetails(qData.value, question).file_path + questionAttachmentDetails(qData.value, question).file_name)">
                            <span v-if="qData.type == 'image'">
                              <a
                                :href="(cloudStorageRoot + questionAttachmentDetails(qData.value, question).file_path + questionAttachmentDetails(qData.value, question).file_name)">{{ questionAttachmentDetails(qData.value,
                                    question).file_name
                                }}</a>
                            </span>
                          </span>

                          <span v-if="(question.maximum_mark && !exam.positive_mark)">
                            (score:{{ question.maximum_mark }})</span>
                        </p>
                      </div>
                      <div class="choices" v-if="question.choices.length != 0">
                        <b-form-radio v-for="({ name, id },
                        choiceIndex) in question.choices" :key="choiceIndex"
                          v-model="examQuestionsAnswer[question.id].choice_id" :value="id">
                          {{ name }}
                        </b-form-radio>
                      </div>
                      <!-- <div v-if="question.files.length != 0" class="text-right">
                        <p class="text-dark m-1">
                          {{
                              question.files.length > 1
                                ? "Question Attachments:"
                                : "Question Attachment:"
                          }}
                        </p>
                        <div class="d-flex flex-column">
                          <span class="text-dark" v-for="(file, attaachmentIndex) in question.files"
                            :key="attaachmentIndex" style="font-size: 12px; font-weight: 600">
                            <a target="_blank" :href="
                              cloudStorageRoot + 
                              file.file_path +
                              file.file_name
                            " class="text-decoration-none text-left">
                              {{ file.file_name }}
                            </a>

                            <embed :src="
                            cloudStorageRoot +
                            file.file_path +
                            file.file_name" height="500px" width="500" />
                          </span>
                        </div>
                      </div> -->
                    </template>
                    <b-card-body v-if="exam.is_objective_only == 0">
                      <div class="answer">
                        <div v-if="question.allow_descriptive">
                          <b-form-input v-model="examQuestionsAnswer[question.id].text"
                            placeholder="Write answer here..."></b-form-input>
                        </div>
                        <p v-else class="text-danger">
                          Only attachments are allowed.
                        </p>
                        <br />

                        <div class="text-right mt-3">
                          <span v-if="question.allow_attachment_answer" class="text-info btn p-0 m-0"
                            @click="newFileInputMask(question.id)"><i class="fas fa-paperclip fa-2x"></i></span>
                          <b-form-file v-if="question.allow_attachment_answer" class="text-info btn p-0 m-0"
                            :ref="'newFileInput' + question.id" type="file" @change="
                              newFileInput($event.target.files, question.id)
                            " style="display: none"></b-form-file>
                          <div class="d-flex justify-content-between">
                            <div class="text-left" style="overflow: hidden">
                              <div class="d-flex flex-column">
                                <span class="text-dark ml-3"
                                  v-for="(file, fileIndex) in examQuestionsAnswer[question.id].files" :key="fileIndex"
                                  style="font-size: 12px; font-weight: 600">
                                  <a :href="
                                    cloudStorageRoot +
                                    file.file_path +
                                    file.file_name
                                  " target="_blank" class="text-decoration-none">{{ file.file_name }}</a>
                                  <span class="btn p-0 m-0 ml-2 text-danger" @click="
                                    deleteQuestionAnswerAttachment(
                                      question.id,
                                      file.id,
                                      fileIndex
                                    )
                                  "><i class="fas fa-trash"></i></span>
                                </span>
                              </div>
                            </div>

                            <div v-if="question.allow_attachment_answer">
                              <span class="text-dark ml-3"
                                v-for="(newFile, newFileIndex) in newQuestionsAnswerAttachments[question.id]"
                                :key="newFileIndex">
                                <b-progress :value="
                                  uploadPercentage[
                                  examQuestionsAnswer[question.id].id
                                  ]
                                " :max="100" show-progress animated></b-progress>
                                <span>
                                  {{ newFile }}

                                  <br />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-overlay>
              </div>
            </div>

            <br />
            <div class="finishExam text-right">
              <b-button :disabled="buttons.saveChanges.disabled" variant="success" size="md"
                @click="saveQuestionsAnswers">{{ buttons.saveChanges.text }}</b-button>
              <b-button :disabled="buttons.saveChanges.disabled" variant="danger" size="md"
                @click="$bvModal.show('finishExam')" class="ml-3">{{
    exam.activity_type == "assessment"
      ? "Finish Exam"
      : "Submit Assignment"
                }}</b-button>
            </div>
            <br />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import * as MathLive from 'mathlive';
Vue.use(MathLive);
import axios from "axios";
import cloudFileUploader from "../../../cloudFileUploader.js";
import VueCountdown from "@chenfengyuan/vue-countdown";
import moment from "moment";
import DisplayResult from "./DisplayResult";
// import { required } from "vuelidate/lib/validators";
export default {
  mixins: [cloudFileUploader],
  async created() {
    // this.exam.id = this.$route.params.exam_id;
    this.fetchData = true;
    // await this.getExam();
    await this.startExam();
    await this.prepareQuestionAnswerForm();
    await this.setExamTime();
    this.fetchData = false;
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
    await MathLive.renderMathInDocument();
  },
  components: {
    VueCountdown,
    DisplayResult,
  },

  data() {
    return {
      fetchData: false,
      loadData: false,
      // userAttachments: "",
      cloudStorageRoot: "",
      exam: {}, //
      examQuestions: [], //
      examAnswers: [], //
      examQuestionsAnswer: {}, //{question_id:answerobject, ...}
      newQuestionsAnswerAttachments: {}, //{question_id:[newly added attachments], ...}
      // uploadPercentage: {}, //{attachmentModelId: 50, ....} // from mixin

      validationErrors: {},
      buttons: {
        saveChanges: {
          disabled: false,
          text: "Save Changes",
        },
      },
      remainingTimeInSeconds: "",
      examRemainingTime: "",
      examStart: null,
      answersValidation: {},
      examFormValidation: this.examValidation,
      participant: {},
      displayResult: false,
    };
  },

  methods: {
    questionAttachmentDetails(attachmentId, question) {
      return question.files.find(item => item.id == attachmentId);
    },
    async startExam() {
      const examId = this.$route.params.exam_id;
      const url =
        this.$store.getters.getAPIKey.examination + `/${examId}/start`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.participant = response.data.examParticipant;
          this.exam = response.data.exam;
          this.examQuestions = response.data.questionsChoices;
        })
        .catch(() => {
          this.$toast.error("Exam time has ended!", {
            position: "top",
            duration: 3000,
          });
          this.examStart = false;
          this.examTimeEnded();
          return;
        });
      await this.examQuestions.forEach((question) => {
        this.$set(this.answersValidation, question.id, {
          validation: false,
          input: false,
          attachment: false,
        });
      });
    },
    // async getExam() {
    //   const url =
    //     this.$store.getters.getAPIKey.examination + `/${this.exam.id}`;
    //   await axios.get(url).then((response) => {
    //     this.exam = response.data;

    //     return response.data;
    //   });
    // },

    setExamTime() {
      // if fnish date
      // getstart date or current time
      // if limitted then find th elowest from linitted or finish
      if (this.exam.activity_type != 'assessment') return;
      var endingTime = moment(
        moment.utc(this.exam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      if (!endingTime.isValid()) return;
      var startTime = moment();
      if (startTime >= endingTime) { // exam end time passed ( less than current time)
        this.$toast.error("Exam time has ended!", {
          position: "top",
          duration: 3000,
        });
        this.examStart = false;
        this.examTimeEnded();
        return;
      }
      let remainingTimeSesconds = endingTime.diff(startTime, 'seconds'); // end time - current time to get remaining seconds

      if (this.exam.limitted_time_seconds) { // time is limitted
        var limitted_time_seconds = this.exam.limitted_time_seconds;
        var userStart = null;
        if (this.participant.starts_at) { // user already started so limitted time should reduce
          userStart = moment(
            moment.utc(this.participant.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
          );

          // time passed grater than limitted time then there is no remaining time
          var timePassed = moment().diff(userStart, 'seconds');
          if (timePassed > limitted_time_seconds) {
            this.$toast.error("Exam time has already ended!", {
              position: "top",
              duration: 3000,
            });
            this.examStart = false;
            this.examTimeEnded();
            return;
          }
          limitted_time_seconds = limitted_time_seconds - timePassed; // reduce available time by already used time 
        }
        // remind time or limitted time, count down start with smallest one
        remainingTimeSesconds = remainingTimeSesconds < limitted_time_seconds ? remainingTimeSesconds : limitted_time_seconds;
      }
      this.remainingTimeInSeconds = remainingTimeSesconds;
      this.examStart = true;

    },
    examTimeEnded() {
      // this.examStart = 0;
      this.$bvModal.show("finishExam");
      this.finishExam();
    },

    // async getExamQuestions() {
    //   const url =
    //     this.$store.getters.getAPIKey.examination +
    //     `/${this.exam.id}/examquestions`;
    //   await axios
    //     .get(url)
    //     .then((response) => {
    //       this.examQuestions = response.data;
    //       this.examQuestions.forEach((question) => {
    //         this.$set(this.answersValidation, question.id, {
    //           validation: false,
    //           input: false,
    //           attachment: false,
    //         });
    //       });

    //       return response.data;
    //       // no questions message in toast-----------------------------------------
    //     })
    //     .catch(() => {
    //       // error;
    //     });
    // },

    async prepareQuestionAnswerForm() {
      // await this.getExamQuestions();

      await this.examQuestions.forEach((question) => {
        this.$set(this.examQuestionsAnswer, question.id, {
          id: null,
          text: "",
          files: [],
          choice_id: null,
        });

        this.newQuestionsAnswerAttachments[question.id] = [];
      });
      await this.getAnswers();

      // this.$forceUpdate();
    },

    async getAnswers() {
      let answers = null;
      // this.$forceUpdate();
      // console.log(this.exam);
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam.id}/institutionuser/examanswers`;
      await axios
        .get(url)
        .then((response) => {
          answers = response.data;
          return (this.examAnswers = answers);
        })
        .catch(() => {
          // error;
        });

      // prepare examquestionsanswe feedExamQuestionsAnswer
      await answers.forEach((answer) => {
        // this.uploadPercentage[answer.id] = 0;
        this.examQuestionsAnswer[answer.examquestion_id] = {
          id: answer.id,
          text: answer.answer == null ? "" : answer.answer,
          files: answer.files,
          choice_id: answer.choice_id == null ? null : answer.choice_id,
          score: answer.score,
        };
      });

      this.$forceUpdate();

      return answers;
    },

    async newFileInput(file, questionId) {
      this.loadData = true;
      await this.saveQuestionsAnswers();
      file = file[0];

      this.newQuestionsAnswerAttachments[questionId].push(file.name);
      let index = this.newQuestionsAnswerAttachments[questionId].indexOf(
        file.name
      );

      await this.uploadNewFile(file, questionId);

      this.newQuestionsAnswerAttachments[questionId].splice(index, 1);

      this.getAnswers();
      this.loadData = false;
    },
    newFileInputMask(questionId) {
      let elementReference = `${"newFileInput" + questionId}`;
      this.$refs[elementReference][0].$refs.input.click();
    },

    async uploadNewFile(file, examQuestionId) {
      // let questionAnswer
      let createAnswer = null;
      createAnswer;

      if (!this.examQuestionsAnswer[examQuestionId].id)
        createAnswer = await this.putQuestionAnswer(examQuestionId);
      // if(!createAnswer) return; // error

      let questionAnswer = this.examQuestionsAnswer[examQuestionId];

      let url = this.$store.getters.getAPIKey.examAnswers;
      let uploadSucces = await this.uploadfile(file, url, questionAnswer.id);
      if (!uploadSucces)
        this.$toast.error(
          "Something went wring when upload file, please try again"
        );

      if (uploadSucces) {
        this.$toast.success(file.name + " Uploaded successfully");
      }
    },

    async putQuestionAnswer(examQuestionId) {
      this.buttons.saveChanges.disabled = true;
      // this.validationErrors[examQuestionId] = null;
      let returnData = null;
      let url = this.$store.getters.getAPIKey.examAnswers;

      let answer = {
        answer: this.examQuestionsAnswer[examQuestionId].text,
        examquestion_id: examQuestionId,
        choice_id: this.examQuestionsAnswer[examQuestionId].choice_id,
      };
      await axios
        .post(url, answer)
        .then((response) => {
          let answer = response.data;
          this.examQuestionsAnswer[examQuestionId] = {
            id: answer.id,
            text: answer.answer == null ? "" : answer.answer,
            files: answer.files,
          };
          returnData = answer;
        })
        .catch((error) => {
          // this.validationErrors[examQuestionId] = true;
          this.$forceUpdate();
          error;
        });
      this.buttons.saveChanges.disabled = false;
      return returnData;
    },

    async deleteQuestionAnswerAttachment(questionId, fileId, fileIndex) {
      this.buttons.saveChanges.disabled = true;
      let answerId = this.examQuestionsAnswer[questionId].id;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examanswers/${answerId}/attachment/${fileId}`;
      await this.$axios
        .delete(url)
        .then(() => {
          this.$toast.success("Attachment Deleted", {
            position: "top",
            duration: 3000,
          });

          // slice and update
          this.examQuestionsAnswer[questionId].files.splice(fileIndex, 1);
          this.$forceUpdate();
          // response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong, Please try again!", {
            position: "top",
            duration: 3000,
          });
          error;
        });
      this.buttons.saveChanges.disabled = false;
    },

    async saveQuestionsAnswers() {
      this.buttons.saveChanges.disabled = true;
      this.buttons.saveChanges.text = "Saving...";
      let returnData = true;
      let answerResponses = {};
      for (let index = 0; index < this.examQuestions.length; index++) {
        answerResponses[
          this.examQuestions[index].id
        ] = await this.putQuestionAnswer(this.examQuestions[index].id);
      }
      for (let index = 0; index < this.examQuestions.length; index++) {
        if (!answerResponses[this.examQuestions[index].id]) returnData = false;
      }

      this.buttons.saveChanges.disabled = false;
      this.buttons.saveChanges.text = "Save changes";
      this.getAnswers();
      if (!returnData) return; // exit

      return returnData;
    },

    async finishExam() {
      // if (this.exam.publish_result == 1 && this.participant.end_at != null) {
      //   this.displayResult = true;
      // } else {
      //   this.buttons.saveChanges.disabled = true;
      //   let saveAnswers = await this.saveQuestionsAnswers();
      //   if (this.exam.publish_result == 1) {
      //     this.displayResult = true;
      //   }
      //   if (!this.examStart && this.exam.publish_result == 0) {
      //     setTimeout(() => {
      //       this.buttons.saveChanges.disabled = true;
      //       this.$router.push({ name: "institute" });
      //     }, 5000);
      //   }
      //   this.buttons.saveChanges.disabled = false;
      //   if (!saveAnswers) return; // error on one of input
      //   // this.$router.push({ name: "institute" });
      // }

      this.buttons.saveChanges.disabled = true;
      let saveAnswers = await this.saveQuestionsAnswers();
      await this.markExamFinish();
      if (!this.examStart) {
        setTimeout(() => {
          this.buttons.saveChanges.disabled = true;
          this.$router.push({ name: "institute" });
        }, 5000);
      }
      this.buttons.saveChanges.disabled = false;
      if (!saveAnswers) return; // error on one of input
      this.$router.push({ name: "institute" });
    },
    async markExamFinish() {
      const url = this.$store.getters.getAPIKey.examination
        + `/${this.exam.id}/finish`;
      await axios
        .get(url)
        .then(() => {
        });
    },
  },
};
</script>
<style >
.time-running-low {
  font-size: 20px !important;
  color: red;
}
</style>
