//-------------------------------------------- v2 -----------------------------------------
//direct cloud file uoload with signed url
// this file handle everything related to cloud file upload
// import thius file and call "uploadfile" method with parameters file object, the resource url, and the is of resource we want to attach file
// if we uploading exam answer attachment then id will be examanswer id / if assignmentanswer then assignmne tanswe id
import axios from "axios";
import Vue from 'vue';
// import VueToast from 'vue-toast-notification';
// import store from "../store"; //pleae inform immidiatly to trubleshoot
export default {
  data() {
    return {
      uploadPercentage: {}, //{attachmentModelId: 50, ....}
      C_F_U_error: null,
      C_F_U_errorResponse: {},
      C_F_U_errorFileProperties: {},
    };
  },
  methods: {
    async uploadfile(file, url, attachmentModelId) {
      this.C_F_U_errorFileProperties['url'] = url;
      this.C_F_U_errorFileProperties['attachmentModelId'] = attachmentModelId;
      
      Vue.set(this.uploadPercentage, attachmentModelId, 0);
      let fileAttribuits = {};
      this.uploadPercentage[attachmentModelId] = 1;
      this.$forceUpdate(); // refresh uploadPercentage chnages to take effect
      fileAttribuits = await this.C_F_U_getSignedUrl(file, url, fileAttribuits);
      this.uploadPercentage[attachmentModelId] = 5;
      
      this.C_F_U_errorFileProperties['signedurlResponse'] = fileAttribuits;

      if (!fileAttribuits) {
        await this.C_F_U_errorHandler()
        return false; // error
      }
      fileAttribuits = await this.C_F_U_uploadToCloud(
        file,
        fileAttribuits,
        attachmentModelId
      );
      
      this.C_F_U_errorFileProperties['cloudResponse'] = fileAttribuits;
      
      if (!fileAttribuits) {
        await this.C_F_U_errorHandler()
        return false; // error
      }

      fileAttribuits.mime = file.type;
      fileAttribuits.size_byte = file.size;
      fileAttribuits.model_id = attachmentModelId;

      fileAttribuits = await this.C_F_U_saveToDatabase(
        url,
        fileAttribuits
      );

      this.C_F_U_errorFileProperties['saveResponse'] = fileAttribuits;

      if (!fileAttribuits) {
        await this.C_F_U_errorHandler()
        return false; // error
      }
      this.uploadPercentage[attachmentModelId] = 100;

      return true; // success
    },

    async C_F_U_getSignedUrl(file, url, fileAttribuits) {
      // var uploadstatus = 0;
      // let url = store.getters.getAPIKey.examAnswers;
      // this.uploadPercentage = 1;
      let returnResponse = null;
      await axios
        .post(url + "/attachment/signedurl", { file_name: file.name })
        .then((response) => {
          // this.uploadPercentage = 5;
          fileAttribuits.file_name = response.data.file_name;
          fileAttribuits.signed_url = response.data.signed_url;
          returnResponse = fileAttribuits;
          //   fileAttribuits = response.data; // {file_name: "", signed_url:""}
        })
        .catch((error) => {
          this.C_F_U_error = "getSignedUrl error";
          this.C_F_U_errorResponse['signedurl'] = error;
          if (error.response)this.C_F_U_errorResponse['signedurl'] = error.response;
          returnResponse = false;
        });
      return returnResponse;
    },

    async C_F_U_uploadToCloud(file, fileAttribuits, attachmentModelId) {
      let returnResponse = null;
      // let uploadProgress;
      var config = {
        headers: { "Content-Type": file.type },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage[attachmentModelId] = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 95)
          );
          // this.uploadPercentage = uploadProgress;
        }.bind(this),
      };
      await axios
        .put(fileAttribuits.signed_url, file, config)
        .then((response) => {
          this.uploadPercentage[attachmentModelId] = 95;
          response;
          returnResponse = fileAttribuits;
        })
        .catch((error) => {
          this.C_F_U_errorResponse['cloud'] = error;
          if (error.response)this.C_F_U_errorResponse['signedurl'] = error.response;
          this.C_F_U_error = "uploadToCloud error";
          returnResponse = false;
        });
      return returnResponse;
    },

    async C_F_U_saveToDatabase(url, fileAttribuits) {
      let returnResponse = null;

      await axios
        .post(url + "/attachment/savefile", fileAttribuits)
        .then((response) => {
          // this.uploadPercentage[attachmentModelId] = 100;
          returnResponse = response.data;
        })
        .catch((error) => {
          this.C_F_U_errorResponse['save'] = error;
          if (error.response)this.C_F_U_errorResponse['signedurl'] = error.response;
          this.C_F_U_error = "saveToDatabase error";
          returnResponse = false;
          
        });
      return returnResponse;
    },

    
    async C_F_U_errorHandler() {
      
      let errorData = {
        message: this.C_F_U_error,
        fileAttribuitsAndApiresponses: this.C_F_U_errorFileProperties,
        apiErrorResponse: this.C_F_U_errorResponse
      };
      let text = JSON.stringify(errorData);

      var element = document.createElement('a');

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/applicationerrors/report/sent`;
      await axios
        .post(url, {errorData: text})
        .then(() => {
          alert("Failed to upload file("+this.C_F_U_error+"), Please try again");
          return true;
        })
        .catch(() => {
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', "share_to_ezygo_error_data.txt");
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          alert("Failed to upload file, Please forward the downloded error data to EzyGo (whatsapp: 8330869500, mail: support@ezygo.app)");
        });
      return true;
    },

  },
  // let cloudFileUpload = {
};

// export default cloudFileUpload;
